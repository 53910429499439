// 
// maintenance.scss
//

.maintenance-icon {
    font-size: 22px;
    box-shadow: $box-shadow-lg;
    height: 60px;
    display: inline-block;
    width: 60px;
    line-height: 58px;
    border-radius: 50%;
}